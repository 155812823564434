import { getRequest, postRequest, deleteRequest } from '@/utils/http'

export async function getKnowledgePoints (params) {
  return getRequest('/official/knowledge-points', params)
}

export async function setKnowledgePointsTags (id, params) {
  return postRequest(`/official/knowledge-point/${id}/set-tags`, params)
}

export async function getKnowledgePointInfo (id) {
  return getRequest(`/official/knowledge-point/${id}`)
}

export async function createKnowledgePoint (params) {
  return postRequest('/official/knowledge-point/create', params)
}

export async function editKnowledgePoint (id, params) {
  return postRequest(`/official/knowledge-point/${id}/edit`, params)
}

export async function deleteKnowledgePoint (id) {
  return deleteRequest(`/official/knowledge-point/${id}/delete`)
}

export async function getPersonalKnowledgePoint (params) {
  return getRequest('/teacher/knowledge-points', params)
}

export async function getPersonalKnowledgePointInfo (id) {
  return getRequest(`/teacher/knowledge-point/${id}`)
}

export async function createPersonalKnowledgePoint (params) {
  return postRequest('/teacher/knowledge-point/create', params)
}

export async function editPersonalKnowledgePoint (id, params) {
  return postRequest(`/teacher/knowledge-point/${id}/edit`, params)
}

export async function createPersonalTags (id, params) {
  return postRequest(`/teacher/knowledge-point/${id}/set-tags`, params)
}

export async function deletePersonalKnowledgePoint (id) {
  return deleteRequest(`/teacher/knowledge-point/${id}/delete`)
}

export const copyKnowledgePoint = async (id) => {
  return postRequest('/teacher/knowledge-point/copy-from-official', { official_knowledge_point_id: id })
}
