<template>
  <a-card class="card_body">
    <template #title>{{ pageState.current.name }}</template>
    <template #extra>
      <a @click="handleGoBack">返回</a>
    </template>
    <a-spin
      :spinning="fullscreenLoading"
      :tip="fullscreenLoadingText"
      size="large"
    >
      <div class="pptistKnowledge" ref="pptistKnowledge"></div>
    </a-spin>
  </a-card>
</template>

<script>
import {
  getKnowledgePointInfo,
  getPersonalKnowledgePointInfo,
  editKnowledgePoint,
  editPersonalKnowledgePoint,
  createKnowledgePoint,
  createPersonalKnowledgePoint
} from '@/services/knowledge-points'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { reactive, ref, onMounted, createVNode, computed } from 'vue'
import { message, Modal } from 'ant-design-vue'
import config from '@/config'
import { eventCenter, event } from '@evideo/frontend-utils'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

export default {
  name: 'Content',
  beforeRouteLeave (to, from) {
    console.log(1, from.path)
    if (from.path.indexOf('/content/')) {
      eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
        app: config.knowledgePoint.name
      })
    }
  },
  props: {
    dev: {
      type: Boolean,
      default: () => false
    }
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const isDev = computed(() => props.dev)
    const getInfoService = route.params.type === '1' ? getKnowledgePointInfo : getPersonalKnowledgePointInfo
    const saveService = route.params.type === '1' ? editKnowledgePoint : editPersonalKnowledgePoint
    const createService = route.params.type === '1' ? createKnowledgePoint : createPersonalKnowledgePoint
    // ppt 编辑 预览 保存（获取json）

    const pageState = reactive({
      loading: true,
      current: {}
    })

    const pptistKnowledge = ref()
    const store = useStore()

    // 全屏加载
    const fullscreenLoading = ref(false)
    const fullscreenLoadingText = ref('')
    const timerList = []

    const loadKnowledgePoint = (content) => {
      eventCenter.send(event.PPTIST_LOAD_KNOWLEDGE_POINT, {
        content: content || ''
      })
    }

    // 加载ppt事件
    const loadPptistApp = () => {
      const knowledgePointUrl = isDev.value ? localStorage.getItem('pptist_url') : config.knowledgePoint.entry
      fullscreenLoading.value = true
      fullscreenLoadingText.value = '初始化准备中，请稍后'
      eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
        app: config.knowledgePoint.name,
        el: pptistKnowledge.value,
        entry: knowledgePointUrl,
        args: {
          token: store.state.token,
          microAppUrl: knowledgePointUrl,
          appName: config.knowledgePoint.name
        },
        editMode: 'knowledgePoints',
        screening: false,
        screenType: 'edit-check'
      }, res => {
        if (res) {
          pageState.loading = false
        }
      })
      eventCenter.sub('knowledgePoint-load-ok', () => {
        const { content } = pageState.current
        loadKnowledgePoint(content || '')
        fullscreenLoading.value = false
        fullscreenLoadingText.value = ''
      })
    }

    // 当前是否是全屏
    const isFullscreen = () => (
      document.mozFullScreen ||
      document.webkitIsFullScreen ||
      document.webkitFullScreen
    )

    // 监听全屏事件
    const registerFullScreenListen = () => {
      // 全屏事件的处理
      eventCenter.sub(event.COMMON_FULL_SCREEN, res => {
        const element = pptistKnowledge.value
        if (res.fullScreen === true) {
          if (!isFullscreen()) {
            // 进入全屏
            if (element.requestFullScreen) {
              element.requestFullScreen()
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen()
            } else if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen()
            } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen()
            }
          }
        } else {
          if (document.exitFullScreen) {
            document.exitFullScreen()
          } else if (document.msExitFullScreen) {
            document.msExitFullScreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
          }
        }
      })
    }

    // 通知父应用展示消息
    const showMessage = (type = 'info', message, el = undefined) => {
      eventCenter.send(event.COMMON_NOTIFY_MESSAGE, {
        type,
        message,
        el
      })
    }

    // 监听保存事件
    const registerSaveListen = () => {
      eventCenter.sub(event.RESOURCE_UPDATE_RESOURCE, async (data, callbackEvent) => {
        const { content, resource_type: resourceType } = data
        if (resourceType !== 1) { return }
        try {
          // 创建
          await saveKnowledge(content)

          // 这里用事件的消息提示是为了兼容全屏的编辑状态
          showMessage('info', '保存成功', isFullscreen() ? pptistKnowledge.value : undefined)
          callbackEvent.reply({ errorcode: 0, mssage: 'success' })
        } catch (error) {
          console.error(error)
          callbackEvent.reply({ errorcode: 1, messge: '保存失败' })
        }
      })
    }

    // 全屏的加载loading
    const showFullScreenLoading = (value, tip = '', timeout = 3000) => {
      fullscreenLoading.value = value
      fullscreenLoadingText.value = tip
      if (value) {
        const timer = setInterval(() => {
          fullscreenLoading.value = false
          fullscreenLoadingText.value = ''
        }, timeout)
        timerList.push(timer)
      } else {
        // 清除所有定时器
        timerList.forEach(timer => {
          clearInterval(timer)
        })
      }
    }
    // 全屏加载
    const registerShowLoadingListen = () => {
      eventCenter.sub(event.COMMON_SHOW_LOADING, (res) => {
        const { loading, timeout, loadingText } = res
        showFullScreenLoading(loading, loadingText, timeout || 3000)
      })
    }

    // 创建知识点
    const createKnowledge = async (content) => {
      try {
        const { name, book_id: bookId, book_section_id: bookSectionId } = pageState.current
        await createService({
          name,
          book_id: bookId,
          book_section_id: bookSectionId,
          content
        })
      } catch (error) {
        console.error(error)
      }
    }

    // 保存知识点
    const saveKnowledge = async (content) => {
      const { id, name, book_id: bookId, book_section_id: bookSectionId } = pageState.current
      await saveService(id, {
        name,
        book_id: bookId,
        book_section_id: bookSectionId,
        content
      })
    }

    const changeCollpased = collpased => {
      eventCenter.send(event.COMMON_CHANGE_COLLPASED, { collpased })
    }

    // 获取知识点信息
    const getInfo = async () => {
      try {
        fullscreenLoading.value = true
        fullscreenLoadingText.value = '加载中请稍后'
        pageState.current = await getInfoService(route.params.id)
      } catch (error) {
        message.error('初始化数据失败，请刷新重试')
        console.error(error)
        throw error
      }
    }

    const checkSave = (next) => {
      eventCenter.send(event.PPTIST_IS_MODIFIED, null, ({ isModified }) => {
        if (isModified) {
          Modal.confirm({
            title: '有尚未保存的ppt内容，确定要离开吗?',
            icon: createVNode(ExclamationCircleOutlined),
            onOk () {
              next()
            }
          })
        } else {
          next()
        }
      })
    }

    // 返回
    const handleGoBack = () => {
      checkSave(() => router.push('/resource/knowledge-points'))
    }

    // 注册相关事件监听
    onMounted(async () => {
      window.scrollTo(0, 0)
      await getInfo()
      changeCollpased(true)
      loadPptistApp()
      registerFullScreenListen()
      registerSaveListen()
      registerShowLoadingListen()
    })

    const handleTest = () => {
      console.log('test')
      showMessage('info', '傻逼测试一下')
    }

    return {
      pageState,
      pptistKnowledge,
      fullscreenLoading,
      fullscreenLoadingText,
      handleGoBack,
      handleTest
    }
  }
}
</script>

<style lang="less" scoped>
.card_body {
  .pptistKnowledge {
    height: 75vh;
  }
  .ant-card-body {
    margin: 0 0;
    padding: 0 0;
  }
}
</style>
